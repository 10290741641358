<style lang="scss">.callout {
  background-color: #661812;
  padding: 3rem 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

h1 {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 0.5rem; }

p {
  font-size: 1.125rem;
  margin-bottom: 2rem; }</style>

<div class="container">
	<div class="callout">
		<h1>Need Something Signed?</h1>
		<p>Request a quote and get in touch!</p>
		<a href="/contact#quote-form" class="btn btn-dark">Request a Quote</a>
	</div>
</div>
