<script lang="ts">import Callout from '../components/Callout.svelte';
import Hero from '../components/Hero.svelte';
import Question from '../components/Question.svelte';
</script>

<style lang="scss">.container {
  max-width: 42rem; }

main {
  display: flex;
  flex-direction: column;
  align-items: center; }</style>

<main role="main">
	<Hero redText="FAQs" subheading="See some answers to the common questions we get" />
	<div class="faq-section container">
		<Question question="What do Notaries Do?">
			<p>
				A notary has the power to perform various official acts, known as “notarial acts”. The
				Code of Virginia specifies five basic notarial acts:
			</p>
			<ol>
				<li>Taking acknowledgments</li>
				<li>Administering oaths and affirmations</li>
				<li>Certifying affidavits or depositions</li>
				<li>Certifying “true copies” of documents</li>
				<li>Verification of fact</li>
			</ol>
		</Question>
		<Question question="What can a mobile notary notarize?">
			<p>
				Anything that can be notarized by a notary, a mobile notary can certify, including
				wills, proof of residency, medical directives, and more. For a full list of documents,
				see the
				<a href="/services">services</a>
				page.
			</p>
		</Question>
		<Question question="What can't be notarized?">
			<p>
				In the state of Virginia, notaries are not able to certify true copies of birth, death,
				or marriage certificates (Only the Division of Vital Records/Statistics may perform such
				a certification). Virginia notaries are not authorized to certify true copies of court
				issued documents. Virginia notaries are not authorized to perform marriage ceremonies.
			</p>
		</Question>
		<Question question="What do I need in order to get my documents notarized?">
			<p>You just need a current photo ID and the document(s) you need notarized.</p>
		</Question>
		<Question question="Does a notary have to notarize my document(s)?">
			<p>A Virginia notary may refuse to notarize a document for any reason.</p>
		</Question>
		<Question question="What’s the difference between a notary and a signing agent?">
			<p>
				Signing agents have extra qualifications on top of being a notary public with their
				state that allows them to assist with loan paperwork and signings (in the mortgage and
				real estate world); all Signing Agents are notaries, but not all notaries are signing
				agents.
			</p>
		</Question>
	</div>
	<Callout />
</main>
