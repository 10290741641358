<script lang="ts">import { route } from '../stores';
import Logo from './Logo.svelte';
// Props
export let navItmes;
</script>

<style lang="scss">footer {
  margin-top: 9rem;
  padding: 3rem 0;
  background-color: #1e1e1e; }

.top-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  @media (min-width: 769px) {
    .top-footer {
      flex-direction: row; } }
  .top-footer .footer-nav-links {
    display: flex;
    flex-direction: row;
    margin-top: 2rem; }
    @media (min-width: 769px) {
      .top-footer .footer-nav-links {
        margin-top: 0; } }
    .top-footer .footer-nav-links li {
      margin-right: 24px; }
      .top-footer .footer-nav-links li:last-of-type {
        margin-right: 0; }
      .top-footer .footer-nav-links li a {
        font-size: 1.125rem; }

.middle-footer {
  display: flex;
  flex-direction: row;
  margin: 2rem auto;
  max-width: 32rem; }
  .middle-footer a {
    flex: 1; }
    .middle-footer a img {
      width: 100%;
      height: 100%; }

.bottom-footer {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .bottom-footer span {
    text-align: center; }
    .bottom-footer span:last-of-type {
      margin: 0; }
  @media (min-width: 769px) {
    .bottom-footer {
      flex-direction: row;
      justify-content: space-between; }
      .bottom-footer span {
        margin: 0; } }</style>

<footer>
	<div class="container">
		<div class="top-footer">
			<Logo bird={false} />
			<ul class="footer-nav-links">
				{#each navItmes as item}
					<li><a class:active={$route === item.link} href={item.link}>{item.text}</a></li>
				{/each}
			</ul>
		</div>
		<div class="middle-footer">
			<a href="https://www.nationalnotary.org/info/choose-nna/" target="_blank">
				<img
					src="./images/nna_member_badge.png"
					alt="Proud Memeber of the National Notary Association"
					title="Proud Memeber of the National Notary Association" />
			</a>
			<a href="https://www.nationalnotary.org/info/choose-nsa" target="_blank">
				<img
					src="./images/nsa_member_badge.png"
					alt="Certified Notary Signing Agent"
					title="Certified Notary Signing Agent" />
			</a>
			<a href="https://www.nationalnotary.org/info/choose-nsa" target="_blank">
				<img
					src="./images/nsa_trained_logo.png"
					alt="Trained Notary Signing Agent"
					title="Trained Notary Signing Agent" />
			</a>
		</div>
		<div class="bottom-footer">
			<span>Copyright © 2020</span>
			<span>Designed & Developed by <a href="#">River City Web Designs</a></span>
		</div>
	</div>
</footer>
