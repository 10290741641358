<script lang="ts">import { fade } from 'svelte/transition';
import { CheckIcon } from 'svelte-feather-icons';
const topFields = [
    {
        label: 'First Name',
        name: 'firstName',
        type: 'text',
        error: '',
        required: true,
        cols: 3,
    },
    {
        label: 'Last Name',
        name: 'lastName',
        type: 'text',
        error: '',
        required: true,
        cols: 3,
    },
    {
        label: 'Email',
        name: 'email',
        type: 'email',
        error: '',
        required: true,
        cols: 3,
    },
    {
        label: 'Phone Number',
        name: 'phone',
        type: 'tel',
        error: '',
        required: true,
        cols: 3,
    },
    {
        label: 'Address Line 1',
        name: 'address1',
        type: 'text',
        error: '',
        required: true,
        cols: 3,
    },
    {
        label: 'Address Line 2',
        name: 'address2',
        type: 'text',
        error: '',
        required: false,
        cols: 3,
    },
    {
        label: 'City',
        name: 'city',
        type: 'text',
        error: '',
        required: true,
        cols: 1,
    },
    {
        label: 'State',
        name: 'state',
        type: 'text',
        error: '',
        required: true,
        cols: 1,
    },
    {
        label: 'Zipcode',
        name: 'zipcode',
        type: 'text',
        error: '',
        required: true,
        cols: 1,
    },
];
let notaryPublicChecked = false;
let signingAgentChecked = false;
let additionalInfo = {
    text: '',
    error: '',
};
</script>

<style lang="scss">.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem; }

.contact-form-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  -moz-column-gap: 1rem;
       column-gap: 1rem; }
  @media (min-width: 496px) {
    .contact-form-upper {
      grid-template-columns: repeat(3, 1fr); } }

img {
  margin: 3rem 0; }

.form-field {
  position: relative; }
  .form-field.form-field--cols-1 {
    grid-column: span 1; }
  .form-field.form-field--cols-2 {
    grid-column: span 1; }
    @media (min-width: 496px) {
      .form-field.form-field--cols-2 {
        grid-column: span 2; } }
  .form-field.form-field--cols-3 {
    grid-column: span 1; }
    @media (min-width: 496px) {
      .form-field.form-field--cols-3 {
        grid-column: span 3; } }
  .form-field label {
    display: block;
    position: absolute;
    color: #838383;
    font-family: 'Yanone Kaffesatz';
    font-size: 1.125rem;
    font-weight: 800;
    left: 1rem;
    bottom: 0.625rem;
    line-height: 1.25rem;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .form-field input:not([type='checkbox']) {
    border: none;
    box-shadow: none;
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: 0 1rem;
    width: 100%;
    background-color: #1e1e1e;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #ffffff;
    caret-color: #d21404;
    margin-top: 3.1875rem;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .form-field input:not([type='checkbox'])::-moz-placeholder {
      color: transparent;
      opacity: 0; }
    .form-field input:not([type='checkbox']):-ms-input-placeholder {
      color: transparent;
      opacity: 0; }
    .form-field input:not([type='checkbox'])::placeholder {
      color: transparent;
      opacity: 0; }
  .form-field .error-text {
    position: absolute;
    right: 0.5rem;
    bottom: 3rem;
    color: #d21404;
    font-family: 'Yanone Kaffesatz';
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.25rem;
    margin: 0; }
  .form-field input:not([type='checkbox']):not(:-moz-placeholder-shown) ~ label {
    bottom: 3rem;
    left: 0.5rem;
    color: #d21404; }
  .form-field input:not([type='checkbox']):not(:-ms-input-placeholder) ~ label {
    bottom: 3rem;
    left: 0.5rem;
    color: #d21404; }
  .form-field input:not([type='checkbox']):focus-within ~ label,
  .form-field input:not([type='checkbox']):not(:placeholder-shown) ~ label {
    bottom: 3rem;
    left: 0.5rem;
    color: #d21404; }
  .form-field input:not([type='checkbox']):not(:-moz-placeholder-shown) + .error-text + label {
    color: #ffffff; }
  .form-field input:not([type='checkbox']):not(:-ms-input-placeholder) + .error-text + label {
    color: #ffffff; }
  .form-field input:not([type='checkbox']):focus-within + .error-text + label,
  .form-field input:not([type='checkbox']):not(:placeholder-shown) + .error-text + label {
    color: #ffffff; }
  .form-field input:not([type='checkbox']):focus-within {
    background-color: #393939;
    box-shadow: 0 3px 6px rgba(210, 20, 4, 0.16), 0 3px 6px rgba(210, 20, 4, 0.23);
    outline: none; }
  .form-field textarea {
    width: 100%;
    background-color: #1e1e1e;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    color: #ffffff;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 600;
    caret-color: #d21404;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .form-field textarea::-moz-placeholder {
      opacity: 0;
      color: transparent; }
    .form-field textarea:-ms-input-placeholder {
      opacity: 0;
      color: transparent; }
    .form-field textarea::placeholder {
      opacity: 0;
      color: transparent; }
    .form-field textarea ~ label {
      top: 0.625rem; }
    .form-field textarea + .error-text {
      top: -1.75rem; }
    .form-field textarea:focus-within {
      background-color: #393939;
      box-shadow: 0 3px 6px rgba(210, 20, 4, 0.16), 0 3px 6px rgba(210, 20, 4, 0.23); }
    .form-field textarea:not(:-moz-placeholder-shown) ~ label {
      top: -1.75rem;
      color: #d21404; }
    .form-field textarea:not(:-ms-input-placeholder) ~ label {
      top: -1.75rem;
      color: #d21404; }
    .form-field textarea:not(:placeholder-shown) ~ label, .form-field textarea:focus-within ~ label {
      top: -1.75rem;
      color: #d21404; }
    .form-field textarea:not(:-moz-placeholder-shown) + .error-text + label {
      color: #ffffff; }
    .form-field textarea:not(:-ms-input-placeholder) + .error-text + label {
      color: #ffffff; }
    .form-field textarea:not(:placeholder-shown) + .error-text + label, .form-field textarea:focus-within + .error-text + label {
      color: #ffffff; }

.lower-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .lower-form .form-field {
    width: 100%; }

.checkboxes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 3rem 0; }
  .checkboxes label {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer; }
  .checkboxes .form-field--checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .checkboxes input {
    opacity: 0;
    position: absolute; }
    .checkboxes input:checked + label {
      color: #ffffff; }
  .checkboxes .checkmark {
    margin: 0;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #1e1e1e;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center; }

input[type='submit'] {
  margin-top: 3rem; }</style>

<form
	action="/#thank-you"
	data-netlify="true"
	name="quote-form"
	method="POST"
	class="contact-form"
	id="quote-form">
	<input type="hidden" name="form-name" value="quote-form" />
	<h2>Contact Info</h2>
	<div class="contact-form-upper">
		{#each topFields as field}
			<div class="form-field form-field--cols-{field.cols}">
				<input
					type={field.type}
					id={field.name}
					name={field.name}
					placeholder={field.label}
					required={field.required} />
				{#if field.error}
					<p class="error-text">{field.error}</p>
				{/if}
				<label for={field.name}>{field.label}</label>
			</div>
		{/each}
	</div>
	<img src="./images/Accent.svg" alt="Separator" />
	<h2>Request Info</h2>
	<div class="lower-form">
		<div class="checkboxes">
			<div class="form-field form-field--checkbox">
				<span
					class="checkmark"
					on:click={() => {
						notaryPublicChecked = !notaryPublicChecked
					}}>
					{#if notaryPublicChecked}
						<div transition:fade={{ duration: 200 }}>
							<CheckIcon class="red-icon" size="24" />
						</div>
					{/if}
				</span>
				<input
					type="checkbox"
					name="notaryPublic"
					tabindex="0"
					id="notaryPublic"
					bind:checked={notaryPublicChecked} />
				<label for="notaryPublic">Notary Public</label>
			</div>
			<div class="form-field form-field--checkbox">
				<span
					class="checkmark"
					on:click={() => {
						signingAgentChecked = !signingAgentChecked
					}}>
					{#if signingAgentChecked}
						<div transition:fade={{ duration: 200 }}>
							<CheckIcon class="red-icon" size="24" />
						</div>
					{/if}
				</span>
				<input
					type="checkbox"
					name="signingAgent"
					id="signingAgent"
					tabindex="0"
					bind:checked={signingAgentChecked} />
				<label for="signingAgent">Signing Agent</label>
			</div>
		</div>
		<div class="form-field">
			<textarea
				required
				name="additional"
				id="additional"
				rows="10"
				placeholder="Additional Info" />
			{#if additionalInfo.error}
				<p class="error-text">{additionalInfo.error}</p>
			{/if}
			<label for="additional">Additional Info</label>
		</div>
	</div>
	<input
		type="submit"
		name="submit"
		id="submit"
		value="Submit Quote Request"
		class="btn btn-primary" />
</form>
