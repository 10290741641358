<script>
	export let redText = ''
	export let text = ''
	export let subheading = ''
</script>

<style lang="scss">.hero {
  margin-top: 10rem;
  margin-bottom: 7.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .hero h1 {
    margin-bottom: 0.5rem; }
    .hero h1 span {
      margin-bottom: 0.5rem; }
  .hero p {
    margin: 0; }</style>

<div class="hero">
	<h1><span class="highlight">{redText}</span> {text}</h1>
	<p>{subheading}</p>
</div>
