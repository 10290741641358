<script lang="ts">import Visibility from '../components/Visibility.svelte';
import ContactForm from '../components/ContactForm.svelte';
import { PhoneIcon, MailIcon } from 'svelte-feather-icons';
import { onMount } from 'svelte';
import { fly, fade } from 'svelte/transition';
$: handshakeSrc =
    screenWidth >= 1024 ? './images/handshake_nobackground.svg' : './images/handshake.svg';
$: documentSrc =
    screenWidth >= 1024 ? './images/document_nobackground.svg' : './images/document.svg';
let screenWidth = window.outerWidth;
window.addEventListener('resize', (e) => {
    screenWidth = window.outerWidth;
});
let showThanks = false;
onMount(() => {
    if (window.location.hash === '#thank-you') {
        showThanks = true;
    }
});
</script>

<style lang="scss">.thanks-message {
  position: fixed;
  z-index: 50;
  bottom: 2rem;
  right: 1rem;
  width: 18.75rem;
  background-color: #d21404;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 1rem;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(210, 20, 4, 0.16), 0 3px 6px rgba(210, 20, 4, 0.23); }
  @media (min-width: 496px) {
    .thanks-message {
      top: 8rem;
      bottom: unset; } }
  @media (min-width: 1440px) {
    .thanks-message {
      right: 4rem; } }
  .thanks-message:hover {
    box-shadow: 0 14px 28px rgba(210, 20, 4, 0.25), 0 10px 10px rgba(210, 20, 4, 0.22); }
  .thanks-message::after {
    content: 'X';
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-family: 'Montserrat';
    color: #ffffff; }

.hero {
  position: relative;
  margin-top: 3rem;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1024px) {
    .hero {
      flex-direction: row;
      min-height: 80vh;
      align-items: center; } }
  .hero .content {
    flex: 4;
    z-index: 10;
    width: 100%; }
    .hero .content h1 {
      margin-bottom: 0.5rem; }
    .hero .content p {
      margin: 0;
      width: 100%; }
      @media (min-width: 1024px) {
        .hero .content p {
          width: 100%; } }
    .hero .content .buttons {
      margin-top: 2rem; }
      .hero .content .buttons a + a {
        margin-top: 1rem; }
    .hero .content h2 {
      margin-top: 2rem;
      margin-left: 1rem; }
    .hero .content ul {
      margin-left: 4rem; }
      .hero .content ul li {
        color: #ffffff;
        font-family: 'Montserrat';
        font-size: 1.125rem;
        margin-bottom: 1rem;
        padding-left: 7px; }
        .hero .content ul li:last-of-type {
          margin-bottom: 0; }
  @media (min-width: 496px) {
    .hero.content--right .content {
      align-self: flex-end;
      width: 50%; }
      .hero.content--right .content p {
        width: 100%; } }
  .hero img {
    flex: 6;
    position: relative;
    width: calc(100% + 32px);
    height: 100%; }
    @media (min-width: 496px) {
      .hero img {
        width: 100%; } }
  .hero img:last-child {
    right: 1rem;
    top: -1.25rem; }
    @media (min-width: 496px) {
      .hero img:last-child {
        top: -10rem;
        right: -1rem; } }
    @media (min-width: 1024px) {
      .hero img:last-child {
        top: 0rem; } }
  .hero img:first-child {
    order: 2;
    left: -1rem;
    top: -1.25rem; }
    @media (min-width: 496px) {
      .hero img:first-child {
        position: absolute;
        width: 50%;
        left: 0;
        transform: scale(1.3);
        align-self: flex-start; } }
    @media (min-width: 1024px) {
      .hero img:first-child {
        position: relative;
        order: 0;
        top: 0rem;
        transform: scale(0.7);
        left: -2rem;
        align-self: center; } }
  @media (min-width: 1024px) {
    .hero.hero--centered {
      min-height: unset; } }
  .hero.hero--centered .centered-content {
    text-align: center; }

.road-image {
  overflow: hidden;
  width: 100%;
  position: relative; }
  .road-image .car {
    max-width: 800px;
    width: 100%;
    position: relative;
    top: 3rem;
    left: -100%;
    float: right; }
    .road-image .car.animate {
      -webkit-animation: car-drive 1s ease-in-out forwards;
              animation: car-drive 1s ease-in-out forwards; }
  .road-image .road {
    min-width: 100%; }

@-webkit-keyframes car-drive {
  from {
    left: -100%; }
  to {
    left: 0; } }

@keyframes car-drive {
  from {
    left: -100%; }
  to {
    left: 0; } }

.contact-form-container {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr; }
  @media (min-width: 1024px) {
    .contact-form-container {
      margin-top: 8rem;
      grid-template-columns: repeat(2, 1fr); } }
  .contact-form-container .quote-text {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .contact-form-container .quote-text h1 {
      margin-bottom: 0.5rem; }
    .contact-form-container .quote-text img {
      margin: 3rem 0; }
    .contact-form-container .quote-text .subheading {
      margin: 0; }
    .contact-form-container .quote-text .info-text {
      text-decoration: none;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .contact-form-container .quote-text .info-text span {
        margin: 0 0 0 1.5rem;
        transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
      .contact-form-container .quote-text .info-text:hover {
        text-decoration: underline; }
        .contact-form-container .quote-text .info-text:hover span {
          color: #ea675d; }
      @media (min-width: 769px) {
        .contact-form-container .quote-text .info-text {
          align-self: flex-start; } }
    @media (min-width: 769px) {
      .contact-form-container .quote-text {
        margin: 0 2rem 0 0; } }</style>

<main role="main">
	{#if showThanks}
		<div
			in:fly={{ x: 200, duration: 500, delay: 500 }}
			out:fade={{ duration: 200 }}
			class="thanks-message"
			on:click={() => (showThanks = false)}>
			<p>Thank you for submitting your request. We will be in contact with you shortly!</p>
		</div>
	{/if}
	<div class="container">
		<section class="hero">
			<div class="content">
				<h1>
					<span class="highlight">Mobile</span>
					Signing Agent &amp;
					<span class="highlight">Notary</span>
					Public
				</h1>
				<p>Mobile & flexible to fit your needs across the Greater Richmond Area</p>
				<div class="buttons">
					<a href="/contact#quote-form" class="btn btn-primary">Request a Quote</a>
					<a href="/services" class="btn btn-primary--ghost">Learn More</a>
				</div>
			</div>
			<img src={handshakeSrc} alt="Handshake with cardinal" />
		</section>
		<section class="hero content--right">
			<img src={documentSrc} alt="Document with Cardinal Stamp" />
			<div class="content">
				<h1><span class="highlight">Documents</span> We Work With</h1>
				<p>
					As a mobile notary service and signing agent service, we have the ability to sign
					many different types of documents
				</p>
				<h2>Mobile Notary</h2>
				<ul class="styled-list">
					<li>Wills</li>
					<li>Powers of Attorney</li>
					<li>Advanced Medican Directive</li>
					<li>Separation Agreements</li>
					<li>Financial Documents</li>
					<li>And Many More...</li>
				</ul>
				<h2>Signing Agent</h2>
				<ul class="styled-list">
					<li>Refinance Loans</li>
					<li>Purchase Loans</li>
					<li>1st & 2nd Mortgages</li>
					<li>Home Equity Loans</li>
					<li>Commercial Loans</li>
					<li>And Many More...</li>
				</ul>
				<div class="buttons">
					<a href="/contact#quote-form" class="btn btn-primary">Request a Quote</a>
					<a href="/services" class="btn btn-primary--ghost">Learn More</a>
				</div>
			</div>
		</section>
		<section class="hero hero--centered">
			<div class="content">
				<div class="centered-content">
					<h1>We Come To <span class="highlight">You</span></h1>
					<p>
						Not matter the convienient time or place, we come to you to make notarizing your
						documents a breeze
					</p>
				</div>
				<h2>Advantages</h2>
				<ul class="styled-list">
					<li>Available to meet at any time or place for any notary service</li>
					<li>Flexibble and timely scheduling to meet your needs</li>
					<li>Honest and efficient performance of notary services</li>
				</ul>
				<div class="buttons">
					<a href="/contact#quote-form" class="btn btn-primary">Request a Quote</a>
				</div>
			</div>
		</section>
	</div>
	<div class="road-image">
		<Visibility bottom={16} target={50} steps={10} let:percent let:unobserve>
			<img
				use:unobserve
				class:animate={percent > 50}
				class="car"
				src="./images/car.svg"
				alt="Cardinal in a car" />
		</Visibility>
		<img class="road" src="./images/Road.svg" alt="The road the cardinal is driving on" />
	</div>
	<div class="container">
		<div class="contact-form-container">
			<div class="quote-text">
				<h1>Get a <span class="highlight">Quote</span></h1>
				<p class="subheading">
					Fill out this form and we will contact you shortly with a quote on the services you
					request. We typically respong within 24 hours of receiving your request.
				</p>
				<img src="./images/Accent.svg" alt="Separator" />
				<h2>Or, Contact Us At</h2>
				<a href="tel:4342604740" class="info-text">
					<PhoneIcon class="red-icon" size="24" />
					<span>(434) 260-4740</span>
				</a>
				<a href="mailto:gracekaiser@hotmail.com" class="info-text">
					<MailIcon class="red-icon" size="24" />
					<span>gracekaiser@hotmail.com</span>
				</a>
			</div>
			<ContactForm />
		</div>
	</div>
</main>
