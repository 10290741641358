<script>
	import Hero from '../components/Hero.svelte'
	import ContactForm from '../components/ContactForm.svelte'
	import { PhoneIcon, MailIcon, ClockIcon } from 'svelte-feather-icons'

	import { onMount } from 'svelte'

	let formHeader
	onMount(() => {
		if (formHeader) {
			if (window.location.hash === '#quote-form') {
				window.scrollTo(0, formHeader.offsetTop)
			}
		}
	})
</script>

<style lang="scss">.inner-container {
  max-width: 39rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.contact-info {
  background-color: #1e1e1e;
  padding: 1.5rem 0; }

.info-items {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  @media (max-width: 495px) {
    .info-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .info-item a,
  .info-item p {
    font-size: 1.125rem;
    margin-top: 1.5rem; }
  @media (min-width: 496px) {
    .info-item {
      flex-direction: row; }
      .info-item a,
      .info-item p {
        font-size: 1.125rem;
        margin-left: 4rem;
        margin-top: 0; } }

img {
  margin: 3rem 0; }
  @media (min-width: 496px) {
    img {
      margin: 3rem 0 3rem 7rem;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content; } }

.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 1rem; }
  .form-header h1 {
    margin-bottom: 0.5rem; }
  .form-header p {
    font-size: 1.125rem;
    text-align: center; }
    @media (min-width: 769px) {
      .form-header p {
        text-align: left; } }</style>

<main role="main">
	<Hero
		redText="Contact"
		text="Us"
		subheading="See how to contact us and our hours of operation" />

	<div class="contact-info">
		<div class="inner-container">
			<div class="info-items">
				<div class="info-item">
					<MailIcon class="red-icon" size="48" />
					<a href="mailto:gracekaiser@hotmail.com" class="email">gracekaiser@hotmail.com</a>
				</div>
				<img src="./images/Accent.svg" alt="null" />
				<div class="info-item">
					<PhoneIcon class="red-icon" size="48" />
					<a href="tel:4342604740" class="email">(434) 260-4740</a>
				</div>
				<img src="./images/Accent.svg" alt="null" />
				<div class="info-item">
					<ClockIcon class="red-icon" size="48" />
					<div class="hours">
						<p><span class="day">Mon:</span>8:00am - 8:00pm</p>
						<p><span class="day">Tues:</span>8:00am - 8:00pm</p>
						<p><span class="day">Wed:</span>8:00am - 8:00pm</p>
						<p><span class="day">Thur:</span>8:00am - 8:00pm</p>
						<p><span class="day">Fri:</span>8:00am - 8:00pm</p>
						<p><span class="day">Sat:</span>8:00am - 8:00pm</p>
						<p><span class="day">Sun:</span>8:00am - 8:00pm</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="inner-container">
		<div class="form-header" bind:this={formHeader}>
			<h1>Get a <span class="highlight">Quote</span></h1>
			<p>
				Fill out this form and we will contact you shortly with a quote on the services you
				request. We typically respond within 24 hours of receiving your request
			</p>
		</div>
		<ContactForm />
	</div>
</main>
