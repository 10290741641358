<script lang="ts">import router from 'page';
import Home from './views/Home.svelte';
import Contact from './views/Contact.svelte';
import Services from './views/Services.svelte';
import About from './views/About.svelte';
import FAQ from './views/FAQ.svelte';
import ErrorPage from './views/ErrorPage.svelte';
import Footer from './components/Footer.svelte';
import Nav from './components/Nav.svelte';
let uid = 1;
const navItems = [
    {
        id: uid++,
        text: 'Contact',
        link: '/contact',
    },
    {
        id: uid++,
        text: 'Services',
        link: '/services',
    },
    {
        id: uid++,
        text: 'About',
        link: '/about',
    },
    {
        id: uid++,
        text: 'FAQ',
        link: '/faq',
    },
];
import { route } from './stores';
let page;
router('/', () => updateRoute(Home, '/'));
router('/contact', () => updateRoute(Contact, '/contact'));
router('/services', () => updateRoute(Services, '/services'));
router('/about', () => updateRoute(About, '/about'));
router('/faq', () => updateRoute(FAQ, '/faq'));
router('/*', () => updateRoute(ErrorPage, ''));
function updateRoute(newPage, newRoute) {
    page = newPage;
    route.set(newRoute);
}
router.start();
</script>

<style lang="scss" global>:global(.btn) {
  padding: 1rem;
  border-radius: 1rem;
  font-family: 'Yanone Kaffesatz';
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-decoration: none; }
  @media (min-width: 496px) {
    :global(.btn) {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; } }

:global(.btn-primary) {
  background-color: #d21404;
  color: #ffffff;
  box-shadow: 0 3px 6px rgba(210, 20, 4, 0.16), 0 3px 6px rgba(210, 20, 4, 0.23);
  transition: box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  :global(.btn-primary:hover) {
    box-shadow: 0 14px 28px rgba(210, 20, 4, 0.25), 0 10px 10px rgba(210, 20, 4, 0.22);
    color: #ffffff; }
  :global(.btn-primary:active) {
    box-shadow: 0 1px 3px rgba(210, 20, 4, 0.12), 0 1px 2px rgba(210, 20, 4, 0.24); }

:global(.btn-primary--ghost) {
  color: #d21404;
  transition: color 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), border-color 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 0.25rem solid #d21404; }
  :global(.btn-primary--ghost:hover) {
    color: #ea675d;
    border-color: #ea675d; }
  :global(.btn-primary--ghost:active) {
    color: #d21404;
    border-color: #d21404; }

:global(.btn-dark) {
  background-color: #1e1e1e;
  color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  :global(.btn-dark:hover) {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    color: #ffffff; }
  :global(.btn-dark:active) {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

:global(*) {
  box-sizing: border-box; }

:global(html),
:global(body) {
  background-color: #2c2c2c;
  margin: 0;
  padding: 0;
  min-height: 100vh; }

:global(body) {
  display: flex;
  flex-direction: column; }

:global(main) {
  flex: 1; }

:global(.container) {
  max-width: 1440px;
  margin-left: 1rem;
  margin-right: 1rem;
  width: calc(100% - 2rem); }
  @media (min-width: 769px) {
    :global(.container) {
      margin-left: 2rem;
      margin-right: 2rem;
      width: calc(100% - 4rem); } }
  @media (min-width: 1441px) {
    :global(.container) {
      margin-left: auto;
      margin-right: auto;
      width: 100%; } }

:global(ul) {
  list-style-type: none;
  margin: 0;
  padding: 0; }

:global(a) {
  font-family: 'Montserrat';
  font-size: 1rem;
  color: #ffffff;
  transition: color 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  :global(a:hover) {
    color: #ea675d; }

:global(.red-icon) {
  color: #d21404; }

@font-face {
  font-family: 'Yanone Kaffesatz';
  src: url("../fonts/YanoneKaffeesatz-VariableFont_wght.ttf"); }

:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
  font-family: 'Yanone Kaffesatz', serif;
  color: #ffffff;
  margin: 0 0 1rem 0; }
  :global(h1) :global(.highlight),
  :global(h2) :global(.highlight),
  :global(h3) :global(.highlight),
  :global(h4) :global(.highlight),
  :global(h5) :global(.highlight),
  :global(h6) :global(.highlight) {
    color: #d21404; }

:global(h1) {
  font-size: 3rem; }

:global(h2) {
  font-size: 2.25rem; }

:global(h3) {
  font-size: 2rem; }

:global(h4) {
  font-size: 1.75rem; }

:global(h5) {
  font-size: 1.25rem; }

:global(h6) {
  font-size: 1rem; }

:global(p),
:global(span) {
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Montserrat';
  margin: 0 0 1rem 0; }

:global(h1) :global(span) {
  font-family: 'Yanone Kaffesatz';
  font-size: 3rem; }

:global(ul.styled-list) {
  list-style-image: url("../images/list_dot.svg");
  list-style-type: disc; }

:global(ol) {
  padding-left: 2rem; }
  :global(ol) :global(li) {
    font-family: 'Montserrat';
    font-size: 1rem;
    color: #ffffff; }
    :global(ol) :global(li:not(:last-of-type)) {
      margin-bottom: 0.5rem; }</style>

<Nav navItmes={navItems} />
<!-- Dynamic Route Here -->
<svelte:component this="{page}"/>
<Footer navItmes={navItems} />
