<script>
	import Callout from '../components/Callout.svelte'
	import Hero from '../components/Hero.svelte'
</script>

<style lang="scss">main {
  display: flex;
  flex-direction: column;
  align-items: center; }

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 42rem; }

img {
  margin-bottom: 3rem;
  max-width: 400px;
  width: 100%;
  flex: 1; }</style>

<main role="main" class="container">
	<Hero redText="About" text="Cardinal Notary" subheading="A little bit about who we are" />
	<div class="about-container">
		<img src="./images/Kaiser.jpg" alt="Grace Kaiser, owner of Cardinal Notary" />
		<p>
			Grace Kaiser is the owner of Cardinal Mobile Notary, a locally owned and operated mobile
			notary and signing agent service.
			<br />
			<br />
			Grace’s passion is helping people, and she has found that being a notary public is an
			excellent way to meet and help others. She lives in Salem, VA, and has done so ever since
			graduating from Virginia Tech in 2018.
			<br />
			<br />
			In her free time, Grace can usually be found drinking tea and crocheting or walking her 3
			year old Siberian Husky, whom she adores.
		</p>
	</div>
	<Callout />
</main>
