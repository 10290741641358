<script lang="ts">export let bird = true;
</script>

<style lang="scss">.logo {
  text-decoration: none;
  transition: unset;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center; }
  .logo:hover .black {
    color: #ffffff; }
  .logo:hover .red {
    color: #d21404; }
  .logo .black,
  .logo .red {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 10px 1rem 4px; }
    .logo .black span,
    .logo .red span {
      font-size: 2.5rem;
      font-family: 'Yanone Kaffesatz';
      margin: 0 0 0 0.75rem; }
      .logo .black span:first-child,
      .logo .red span:first-child {
        margin: 0; }
  .logo .black {
    background-color: #000000;
    border-radius: 1rem; }
  .logo .red span {
    color: #d21404; }
    @media (max-width: 360px) {
      .logo .red span {
        display: none; } }</style>

<a href="/" class="logo">
	<div class="black">
		{#if bird}<img src="./images/Bird.svg" alt="Bird Logo of Cardinal Notary" />{/if}
		<span>Cardinal</span>
	</div>
	<div class="red"><span>Notary</span></div>
</a>
