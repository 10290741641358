<script lang="ts">import { fade } from 'svelte/transition';
import { route } from '../stores';
import Logo from './Logo.svelte';
// props
export let navItmes;
let open = false;
$: {
    $route;
    open = false;
}
let y;
let birds = [];
navItmes.forEach(item => {
    birds = [...birds, false];
});
</script>

<style lang="scss">header {
  top: -1px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 200;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  header .container {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  header.raised {
    background-color: rgba(15, 15, 15, 0.9);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

a {
  text-decoration: none; }

.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

svg {
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  svg line {
    stroke: #a4261b;
    stroke-width: 4px;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  svg #top {
    transform-origin: 100% 4px; }
  svg #bottom {
    transform-origin: 100% 28px; }
  svg #middle {
    transform-origin: 100% 18px; }
  svg.open {
    transform: scale(0.8); }
    svg.open #top {
      transform: translate(-4.5px, -0.7px) rotate(-45deg); }
    svg.open #bottom {
      transform: translate(-4.5px, 0.7px) rotate(45deg); }
    svg.open #middle {
      transform: scaleX(0); }

button {
  border: none !important;
  background: unset;
  padding: 0;
  cursor: pointer;
  height: 40px;
  z-index: 110; }
  @media (min-width: 769px) {
    button {
      display: none; } }

.mobile-nav {
  z-index: 100;
  position: fixed;
  width: 0;
  overflow: hidden;
  top: 0;
  right: 0;
  height: 100%;
  background-color: rgba(15, 15, 15, 0.9);
  transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  max-width: 100vw; }
  @media (min-width: 769px) {
    .mobile-nav {
      display: none; } }
  .mobile-nav.open {
    width: 300px; }
  .mobile-nav ul {
    margin-top: 120px; }
    .mobile-nav ul li {
      padding: 0.5rem 1rem; }
      .mobile-nav ul li a {
        font-size: 1.25rem; }
        .mobile-nav ul li a.active {
          color: #ea675d; }

.desktop-nav {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%; }
  @media (min-width: 769px) {
    .desktop-nav {
      display: flex; } }
  .desktop-nav li {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center; }
    .desktop-nav li:last-of-type a {
      margin-right: 0; }
  .desktop-nav a {
    font-size: 2.5rem;
    font-family: 'Yanone Kaffesatz';
    margin-right: 2rem; }
    .desktop-nav a.active {
      color: #ea675d; }

.nav-bird {
  display: none;
  position: absolute;
  width: 19px;
  height: 24px;
  top: -12px;
  left: -9px; }
  @media (min-width: 769px) {
    .nav-bird {
      display: block; } }</style>

<!-- For scroll position -->
<svelte:window bind:scrollY={y} />
<header class:raised={y > 0}>
	<div class="container">
		<Logo />
		<nav class="nav">
			<button
				class="mobile-nav-button"
				on:click={() => {
					open = !open
				}}>
				<svg width="40" height="32" class:open>
					<line id="top" x1="40" y1="2" x2="0" y2="2" />
					<line id="middle" x1="40" y1="16" x2="0" y2="16" />
					<line id="bottom" x1="40" y1="30" x2="0" y2="30" />
				</svg>
			</button>
			<ul class="desktop-nav">
				{#each navItmes as item}
					<li
						on:mouseover={() => (birds[item.id] = true)}
						on:mouseout={() => (birds[item.id] = false)}>
						{#if birds[item.id]}
							<img
								src="./images/Bird.svg"
								alt="Nav Bird for hover effect"
								transition:fade
								class="nav-bird" />
						{/if}
						<a class:active={$route === item.link} href={item.link}>{item.text}</a>
					</li>
				{/each}
			</ul>
		</nav>
	</div>
</header>

<nav class="mobile-nav" class:open>
	<ul>
		{#each navItmes as item}
			<li><a class:active={$route === item.link} href={item.link}>{item.text}</a></li>
		{/each}
	</ul>
</nav>
